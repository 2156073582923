import emailjs from "emailjs-com";

export const sendEmail = (num) => {
  const templateParams = {
    message: `You got a new visiter with this number: ${num}`,
  };

  emailjs
    .send(
      process.env.REACT_APP_MAIL_SERVICE_ID,
      process.env.REACT_APP_MAIL_TEMPLATE_ID,
      templateParams,
      process.env.REACT_APP_MAIL_PUBLIC_ID
    )
    .then(
      (result) => {
        console.log("Email successfully sent!", result.text);
      },
      (error) => {
        console.log("Failed to send email.", error.text);
      }
    );
};
