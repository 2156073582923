import React from 'react';
import "./style.css"
import { useNavigate } from 'react-router-dom';


//images
import cancellationBgImg2 from "../../Assets/Images/cancellationBgImg2.png";


//components
import { Box, Typography } from "@mui/material";
import NavBar from '../../Components/NavBar';
import Footer from "../../Components/Footer";



export default function PricingPage({ langDropVal, setLanDropVal, num, setNum, otpPop, setOtpPop, changeNumPop, setChangeNumPop, thankPop, setThankPop, expTime, setExpTime, OTPValidTime }) {
  const relative = true
  const navigate = useNavigate();



  return (
    <>
      <Box className="policyContainer">
        <NavBar langDropVal={langDropVal} setLanDropVal={setLanDropVal} />

        <Box className="policyNav">
          <Typography className="policyNavText"
            onClick={() => navigate("/Terms")}>
            Terms Condition
          </Typography>
          <Typography className="policyNavText"
            onClick={() => navigate("/Policy")}
          >
            Privacy policy
          </Typography>
          <Typography className="policyNavText"
            onClick={() => navigate("/Cancellation")}
          >
            Cancellation policy
          </Typography>
          <Typography className="policyNavText policyNavActiveText"
            onClick={() => navigate("/Pricing")}
          >
            Pricing
          </Typography>
        </Box>
        <Box className="policyPageSection pricingPage">
          <Typography textAlign={"center"} className="policyHeader">Pricing</Typography>

          <Box className="pricMainBox">
            <Box className="pTextBox">
              <Typography className='policyHeader1'>Transaction Charges</Typography>
              <Typography className='policyHeader1'>[2%]</Typography>
              <Typography className='policyHeader1'>+</Typography>
              <Typography className='policyHeader1'>Platform Fee</Typography>
              <Typography className='policyHeader1'>[2%]</Typography>
              <Typography className='policyHeader1'>+</Typography>
              <Typography className='policyHeader1'>Base Trek Charges</Typography>
            </Box>

            <Box className="pImgBox">
              <img src={cancellationBgImg2} />
            </Box>

          </Box>

        </Box>

        <Footer otpPop={otpPop} setOtpPop={setOtpPop} num={num} setNum={setNum} changeNumPop={changeNumPop} setChangeNumPop={setChangeNumPop} thankPop={thankPop} setThankPop={setThankPop} expTime={expTime} setExpTime={setExpTime} OTPValidTime={OTPValidTime} relative={relative} />

      </Box>
    </>
  )
}
