import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";
import axios from "axios";

//images
import CLogo from "../../Assets/Images/bookMyC2.png";
import twitterIcon from "../../Assets/Images/twitterIcon.png";
import facebookIcon from "../../Assets/Images/facebookIcon.png";
import instaIcon from "../../Assets/Images/instagramIcon.png";
import gitIcon from "../../Assets/Images/gitHubIcon.png";
import playStorBtn from "../../Assets/Images/playSotoreBtn.png";
import appelStoreBtn from "../../Assets/Images/appelBtn.png";
import whatsapIcom from "../../Assets/Images/whatsappIcon.png";
import callIcom from "../../Assets/Images/callIcon.png";
import chatIcon from "../../Assets/Images/cheatIcon.png";

import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { sendOTP } from "../../utility/SendOTP";
import OTPpop from "../../Components/OTPpop"
import ChangeNumPop from "../../Components/ChangeNumPop"
import ThankPop from "../../Components/ThankPop"


export default function Footer({ otpPop, setOtpPop, num, setNum, changeNumPop, setChangeNumPop, thankPop, setThankPop, expTime, setExpTime, OTPValidTime, relative }) {
  const [t, i18n] = useTranslation("global");
  const navigat = useNavigate();

  const handleCallBtn = async () => {
    sendOTP({ num });
    setOtpPop(true);
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  };

  const goHomePage = () => {
    navigat("/")
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <>
      <Box className="footer" sx={{ position: relative ? "relative" : "unset" }}>
        <OTPpop otpPop={otpPop} setOtpPop={setOtpPop} setChangeNumPop={setChangeNumPop} setThankPop={setThankPop} expTime={expTime} setExpTime={setExpTime} OTPValidTime={OTPValidTime} />
        <ChangeNumPop changeNumPop={changeNumPop} setChangeNumPop={setChangeNumPop} num={num} setNum={setNum} setOtpPop={setOtpPop} setExpTime={setExpTime} OTPValidTime={OTPValidTime} />
        <ThankPop thankPop={thankPop} setThankPop={setThankPop} num={num} />


        <img src={chatIcon} className="chatIcon" />
        <Box className="footerMainSection">
          <Box className="footerItemBox">
            <img src={CLogo} onClick={goHomePage} />
            <Typography className="foB1Text">
              {t("Footer.footerSummery")}
            </Typography>
            <Box className="footerIconBox">
              <img src={twitterIcon} />
              <img src={facebookIcon} />
              <img src={instaIcon} />
              <img src={gitIcon} />
            </Box>
          </Box>

          <Box className="footerItemBox storBtnBox">
            <img src={playStorBtn} />
            <img src={appelStoreBtn} />
          </Box>

          <Box className="footerItemBox callInputB">
            <Box className="callInputbox">
              <input
                type="number"
                placeholder={t("Footer.EnterUNumber")}
                onChange={(e) => setNum(e?.target?.value)}
                value={num}
              />
              <Box
                className={
                  num?.length > 9 ? "callBtn" : "callBtn callBtndisable"
                }
                onClick={handleCallBtn}
              >
                <Typography>{t("navText.callUs")}</Typography>
              </Box>
            </Box>

            <Box className="homeContactBox">
              <Box className="navItem">
                <Box className="NIconBox">
                  <img src={callIcom} />
                </Box>
                <Box>
                  <Typography>{t("navText.callUs")}</Typography>
                  <Typography>99999999</Typography>
                </Box>
              </Box>

              <Box className="navItem">
                <Box className="NIconBox">
                  <img src={whatsapIcom} />
                </Box>
                <Box>
                  <Typography>{t("navText.WhatsApp")}</Typography>
                  <Typography>99999999</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="footerBottombox">
          <Typography> {t("Footer.Copyright")}
            <span onClick={() => {
              navigat("/Terms")
              window.scrollTo({ top: 0, behavior: 'smooth' })
            }}>{t("Footer.TermsAConditions")}</span>|
            <span onClick={() => {
              navigat("/Policy")
              window.scrollTo({ top: 0, behavior: 'smooth' })
            }}>{t("Footer.PrivacyPolicy")}</span>|
            <span onClick={() => {
              navigat("/Cancellation")
              window.scrollTo({ top: 0, behavior: 'smooth' })
            }}>{t("Footer.CancellationPolicy")}</span>
          </Typography>
        </Box>
      </Box>
    </>
  );
}
