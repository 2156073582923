import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

//pages
import Home from "./Pages/Home";
import TermsConditionPage from "./Pages/TermsCondition";
import PrivacyPolicyPage from "./Pages/PrivacyPolicy";
import CancellationPolicyPage from "./Pages/CancellationPolicy";
import PricingPage from "./Pages/PricingPage";

export default function App() {
  const OTPValidTime = 30;
  const [langDropVal, setLanDropVal] = useState("English");
  const [num, setNum] = useState();
  const [otpPop, setOtpPop] = useState(false);
  const [changeNumPop, setChangeNumPop] = useState(false);
  const [thankPop, setThankPop] = useState(false);
  const [expTime, setExpTime] = useState(OTPValidTime);

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <Home
              langDropVal={langDropVal}
              setLanDropVal={setLanDropVal}
              num={num}
              setNum={setNum}
              setOtpPop={setOtpPop}
              otpPop={otpPop}
              changeNumPop={changeNumPop}
              setChangeNumPop={setChangeNumPop}
              expTime={expTime}
              setExpTime={setExpTime}
              OTPValidTime={OTPValidTime}
              setThankPop={setThankPop}
              thankPop={thankPop}
            />
          }
        />

        <Route
          path="Terms"
          element={
            <TermsConditionPage
              langDropVal={langDropVal}
              setLanDropVal={setLanDropVal}
              num={num}
              setNum={setNum}
              setOtpPop={setOtpPop}
              otpPop={otpPop}
              changeNumPop={changeNumPop}
              setChangeNumPop={setChangeNumPop}
              expTime={expTime}
              setExpTime={setExpTime}
              OTPValidTime={OTPValidTime}
              setThankPop={setThankPop}
              thankPop={thankPop}
            />
          }
        />
        <Route
          path="Policy"
          element={
            <PrivacyPolicyPage
              langDropVal={langDropVal}
              setLanDropVal={setLanDropVal}
              num={num}
              setNum={setNum}
              setOtpPop={setOtpPop}
              otpPop={otpPop}
              changeNumPop={changeNumPop}
              setChangeNumPop={setChangeNumPop}
              expTime={expTime}
              setExpTime={setExpTime}
              OTPValidTime={OTPValidTime}
              setThankPop={setThankPop}
              thankPop={thankPop}
            />
          }
        />
        <Route
          path="Cancellation"
          element={
            <CancellationPolicyPage
              langDropVal={langDropVal}
              setLanDropVal={setLanDropVal}
              num={num}
              setNum={setNum}
              setOtpPop={setOtpPop}
              otpPop={otpPop}
              changeNumPop={changeNumPop}
              setChangeNumPop={setChangeNumPop}
              expTime={expTime}
              setExpTime={setExpTime}
              OTPValidTime={OTPValidTime}
              setThankPop={setThankPop}
              thankPop={thankPop}
            />
          }
        />
        <Route
          path="Pricing"
          element={
            <PricingPage
              langDropVal={langDropVal}
              setLanDropVal={setLanDropVal}
              num={num}
              setNum={setNum}
              setOtpPop={setOtpPop}
              otpPop={otpPop}
              changeNumPop={changeNumPop}
              setChangeNumPop={setChangeNumPop}
              expTime={expTime}
              setExpTime={setExpTime}
              OTPValidTime={OTPValidTime}
              setThankPop={setThankPop}
              thankPop={thankPop}
            />
          }
        />
      </Routes>
    </>
  );
}
