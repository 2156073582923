import React from 'react';
import "./style.css"
import { useNavigate } from 'react-router-dom';


//components
import { Box, Typography } from "@mui/material";
import NavBar from '../../Components/NavBar';
import CancellationPolicy from "../../Components/CancellationPolicy";
import Footer from "../../Components/Footer";


export default function CancellationPolicyPage({ langDropVal, setLanDropVal, num, setNum, otpPop, setOtpPop, changeNumPop, setChangeNumPop, thankPop, setThankPop, expTime, setExpTime, OTPValidTime }) {
  const relative = true
  const navigate = useNavigate();


  return (
    <>
      <Box className="policyContainer">
        <NavBar langDropVal={langDropVal} setLanDropVal={setLanDropVal} />

        <Box className="policyNav">
          <Typography className="policyNavText"
            onClick={() => navigate("/Terms")}>
            Terms Condition
          </Typography>
          <Typography className="policyNavText"
            onClick={() => navigate("/Policy")}>

            Privacy policy
          </Typography>
          <Typography className="policyNavText policyNavActiveText"
            onClick={() => navigate("/Cancellation")}
          >
            Cancellation policy
          </Typography>
          <Typography className="policyNavText"
            onClick={() => navigate("/Pricing")}
          >
            Pricing
          </Typography>
        </Box>
        <CancellationPolicy />
        <Footer otpPop={otpPop} setOtpPop={setOtpPop} num={num} setNum={setNum} changeNumPop={changeNumPop} setChangeNumPop={setChangeNumPop} thankPop={thankPop} setThankPop={setThankPop} expTime={expTime} setExpTime={setExpTime} OTPValidTime={OTPValidTime} relative={relative} />

      </Box>
    </>
  )
}
