import axios from "axios";

export const sendOTP = async ({ num }) => {
  const OTP = Math.floor(1000 + Math.random() * 9000);
  localStorage.setItem("varValue", OTP);
  localStorage.setItem("varNumber", num);

  const url = process.env.REACT_APP_OTP_URL;
  if (num) {
    const params = {
      authorization: process.env.REACT_APP_AUTH_URL,
      route: "dlt",
      sender_id: process.env.REACT_APP_OTP_SERVICE_ID,
      message: "170510",
      variables_values: OTP,
      flash: "0",
      numbers: num,
    };
    try {
      const response = await axios.get(url, { params });
      console.log("Response:", response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  }
};
